import React from 'react';
import { ButtonWrapper } from '../ButtonWrapper';
import {
  ServiceData,
  SlotService,
} from '../../../../../utils/state/initialStateFactory';
import { mapToArray } from '../../../../../utils';
import { FormStatus } from '../../../../../types/form-state';
import { FormError } from '../../../../../types/errors';
import { ButtonWrapperDataHooks } from '../dataHooks';
import { CartFlow } from '../../../../../types/types';

export interface CartBookButtonsProps {
  serviceData: ServiceData;
  shouldShowCollapseForm?: boolean;
  status: FormStatus;
  errors: FormError[];
}

export const CartBookButtons: React.FC<CartBookButtonsProps> = ({
  serviceData,
  status,
  errors,
  shouldShowCollapseForm,
}) => {
  // TODO: Support multi services
  const singleSlotService = mapToArray<SlotService>(
    serviceData.slotServices,
  )[0];
  const { service, selectedPaymentOption } = singleSlotService;

  return (
    <>
      <ButtonWrapper
        dataHook={ButtonWrapperDataHooks.CART_ADD_TO_CART_CTA}
        isCart
        cartFlow={CartFlow.ADD_MORE_SESSIONS}
        isPendingApprovalFlow={service.isPendingApprovalFlow}
        actionLabels={service.actionLabels}
        paymentTypes={service.paymentTypes}
        selectedPaymentOption={selectedPaymentOption}
        status={status}
        errors={errors}
        summaryPaymentDetails={serviceData.summaryPaymentDetails}
        loading={status === FormStatus.PROCESSING_BOOK_REQUEST}
      />
      {!shouldShowCollapseForm && (
        <ButtonWrapper
          dataHook={ButtonWrapperDataHooks.CART_CHECKOUT_CTA}
          isPendingApprovalFlow={false}
          isCart
          cartFlow={CartFlow.CHECKOUT}
          actionLabels={{}}
          paymentTypes={[]}
          selectedPaymentOption={selectedPaymentOption}
          status={status}
          errors={[]}
          summaryPaymentDetails={serviceData.summaryPaymentDetails}
          loading={status === FormStatus.PROCESSING_CART_BOOK_NOW_REQUEST}
        />
      )}
    </>
  );
};
