import React from 'react';
import { ButtonWrapper } from '../ButtonWrapper';
import {
  ServiceData,
  SlotService,
} from '../../../../../utils/state/initialStateFactory';
import { mapToArray } from '../../../../../utils';
import { FormStatus } from '../../../../../types/form-state';
import { FormError } from '../../../../../types/errors';

export interface BuyPlanButtonProps {
  serviceData: ServiceData;
  status: FormStatus;
  errors: FormError[];
}

export const BookButton: React.FC<BuyPlanButtonProps> = ({
  serviceData,
  status,
  errors,
}) => {
  // TODO: Support multi services
  const singleSlotService = mapToArray<SlotService>(
    serviceData.slotServices,
  )[0];
  const { service, selectedPaymentOption } = singleSlotService;

  return (
    <ButtonWrapper
      isPendingApprovalFlow={service.isPendingApprovalFlow}
      actionLabels={service.actionLabels}
      paymentTypes={service.paymentTypes}
      selectedPaymentOption={selectedPaymentOption}
      status={status}
      errors={errors}
      summaryPaymentDetails={serviceData.summaryPaymentDetails}
    />
  );
};
