import React from 'react';
import Text, { TextType } from '../../../../Text/Text';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
} from '../../../../../../types/types';
import { TagName } from '../../../../../../utils/tagName.const';
import { PaymentDetails } from '../../../PaymentDetails/PaymentDetails';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { SlotService } from '../../../../../../utils/state/initialStateFactory';
import { getPriceLineText } from '../../../../../../utils/payment/payment';
import { SelectedVariants } from '@wix/bookings-uou-types';

export type PriceLineProps = {
  dataHook: string;
  dateRegionalSettingsLocale: string;
  selectedPaymentOption: PaymentOption;
  slotService: SlotService;
  selectedVariant?: SelectedVariants;
  totalNumberOfParticipants?: number;
};

const PriceLine: React.FC<PriceLineProps> = ({
  dataHook,
  dateRegionalSettingsLocale,
  selectedPaymentOption,
  slotService,
  selectedVariant,
  totalNumberOfParticipants,
}) => {
  const { t } = useTranslation();

  if (selectedPaymentOption.id === ReservedPaymentOptionIds.SingleSession) {
    const priceText: string | null = getPriceLineText({
      t,
      dateRegionalSettingsLocale,
      slotService,
      selectedVariant,
      totalNumberOfParticipants,
    });
    return priceText ? (
      <Text
        type={TextType.Secondary}
        data-hook={dataHook}
        tagName={TagName.Paragraph}
      >
        {priceText}
      </Text>
    ) : null;
  } else {
    return <PaymentDetails paymentOption={selectedPaymentOption} />;
  }
};

export default PriceLine;
