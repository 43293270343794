import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  ServiceData,
  SlotServices,
} from '../../../../utils/state/initialStateFactory';
import { FormStatus } from '../../../../types/form-state';
import { BuyPlanButton } from './BuyPlanButton';
import { BookButton } from './BookButton';
import { CartBookButtons } from './CartBookButtons';
import { FormError } from '../../../../types/errors';
import {
  checkDifferentBuyPlans,
  getExceedLimitedPricePlans,
  getSelectedServicesForBuyPlan,
} from '../../../../utils/payment/payment';
import {
  SectionMessage,
  SectionMessageType,
} from '../SectionMessage/SectionMessage';
import { classes } from './ButtonContainer.st.css';

export interface BookButtonContainerProps {
  serviceData: ServiceData;
  isCart?: boolean;
  status: FormStatus;
  shouldShowCollapseForm?: boolean;
  errors: FormError[];
}

interface AlertMessageProps {
  slotServices: SlotServices;
}

const ButtonsContainer: React.FC<BookButtonContainerProps> = ({
  serviceData,
  isCart,
  status,
  shouldShowCollapseForm,
  errors,
}) => {
  const slotServicesWithBuyPlans = getSelectedServicesForBuyPlan(
    serviceData.slotServices,
  );
  const messageAlert = (
    <RenderAlertMessage slotServices={serviceData.slotServices} />
  );

  if (slotServicesWithBuyPlans.length) {
    return (
      <>
        {messageAlert}
        <BuyPlanButton
          slotServices={serviceData.slotServices}
          status={status}
          errors={errors}
        />
      </>
    );
  }

  if (isCart) {
    return (
      <>
        {messageAlert}
        <CartBookButtons
          serviceData={serviceData}
          shouldShowCollapseForm={shouldShowCollapseForm}
          status={status}
          errors={errors}
        />
      </>
    );
  }

  return (
    <>
      {messageAlert}
      <BookButton serviceData={serviceData} status={status} errors={errors} />
    </>
  );
};

const RenderAlertMessage: React.FC<AlertMessageProps> = ({ slotServices }) => {
  const { t } = useTranslation();

  const isDifferentBuyPlan = checkDifferentBuyPlans(slotServices);
  const exceedPlans = getExceedLimitedPricePlans(slotServices);

  if (!isDifferentBuyPlan && !exceedPlans?.length) {
    return null;
  }

  let alertText = t('app.bookings-form.right-side-alert.too-many-plans');
  if (exceedPlans?.length) {
    const planNames = exceedPlans.map((plan) => plan.label).join(', ');
    alertText = t(
      'app.bookings-form.right-side-alert.not-enough-sessions-left-price-plan',
      { planNames: `'${planNames}'` },
    );
  }

  return (
    <div className={classes.root}>
      <SectionMessage
        type={SectionMessageType.Alert}
        text={alertText}
        withoutIcon={true}
      />
    </div>
  );
};

export default ButtonsContainer;
